.profile{
    margin-top: 20px;
}

.profile-titlte{
    font-size: 16px;
    font-weight: 550;
    color: #FFFFFF;
}

.action{
    margin-top:15px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}
.action-only{
    margin-top:15px;
    display: flex;
    justify-content: center;
    height: 76px;
}
.action-verify{
    justify-content: center;
}

.action-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 191px;
    font-weight: bold;
    color: #FFFFFF;
    background: linear-gradient(180deg, #000000 0%, #313131 48.44%, #000000 100%);
    border-radius: 13px;
    border: 2px solid #ccc6c6;
    padding: 8px;
    height: 76px;
    font-size: 12px;
}

.action-btn:hover{
    background: linear-gradient(0deg, #202020 48.44%, #202020 48.44%);
}
.action-btn img{
    max-width: 46px;
    max-height: 52px;
    margin-right: 8px;
}
.modal-info{
    max-width: 400px;
    width: 98%;
    height: 100vh;
    color:white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin: auto;
    position: relative;
    padding: 15px 0;
}

.modal-info::-webkit-scrollbar {
    display: none;
}

.icon-close{
    background: none;
    border: none;
    cursor: pointer;
}

.modal-titlte{
    font-size: 16px;
    font-weight: 550;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
}

.input-info {
    display: flex;
    flex-direction: column;
    border: 1.8px solid #FFAA2C;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #333333;
}

.input-info input {
    margin: 15px 10px 0px 10px;
    padding: 7px;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 2px solid #5D5D5D;
    font-size: 16px;
    color: #FFFFFF;
}

.input-info input:last-child {
    margin-bottom: 25px;
}

::placeholder {
    color: #C1C1C1;
    font-size: 16px;
    font-weight: 400;
}

.action-verify-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 181px;
    height: 43px;
    font-weight: bold;
    color: #FFFFFF;
    background: linear-gradient(#000000 0%, #313131 48%, #000000 100%);
    border-radius: 25px;
    border-right: 4px solid #ccc6c6;
    border-left: 4px solid #ccc6c6;
    border-top: 1px solid #ccc6c6;
    border-bottom: 1px solid #ccc6c6;
}

.action-verify-btn:hover{
    color:#000000;
    background: linear-gradient(180deg, #F49A25 0%, #F7B922 45.46%, #F7B922 51.75%, #F49A25 96.73%);
}

.action-verify-btn img{
    margin-top:5px;
}

.mode-verify{
    margin-bottom:150px;
}

.modal-confirm,.modal-success{
    background-color: #FFFFFF;
    max-width: 400px;
    width: 96%;
    border-radius: 12px;
    margin: auto;
    position: relative;
}
.modal-confirm .modal-close, .modal-success .modal-close, .modal-info .modal-close{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 99;
}
.modal-success{
    padding: 24px 0;
}
.modal-confirm-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:8px;
    text-align: center;
}
.icon-warning{
    max-width: 60px;
    height: auto;
}

.modal-confirm-content p{
    font-size: 16px;
    font-weight: 500;
}

.modal-confirm-content span {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.notification {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    /* padding: 10px; */
    padding: 5px 10px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333333;
    margin: 10px 0;
    text-align: left;
}

.notification p {
    margin: 2px 0;
    padding: 0px 0px 0px 10px;
}

.notification a {
    color: #007bff;
    text-decoration: none;
}

.notification a:hover {
    text-decoration: underline;
}

.content-text-confirm{
    max-width: 320px;
    width: 96%;
    margin: auto;
    padding:8px;
    text-align: center;
}
.content-text-confirm span {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    display: flex;
}


.btn-send-mail{
    cursor: pointer;
    margin-top: 10px;
    border-radius: 25px;
    color:#FFFFFF;
    border: 1px solid #FFCD00;
    background: linear-gradient(90deg, #F7B922 0%, #F49A25 100%);
    padding: 8px 25px 8px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.text-confirm-bottom{
    font-weight: 700 !important;
    font-style: italic;
    margin: 0;
}
.modal-result-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.modal-result-content span{
    padding: 0px 5px 15px 5px;
    font-weight: bold;
    font-style: italic;
}
.popup-content {
    width: 100%;
}
.Collapsible {
    width: 100%;
}
.Collapsible__contentOuter{
    width: 100%;
}
.Collapsible__trigger {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 550;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
}

.Collapsible__trigger.is-open img {
    
    transform: rotate(180deg);
}

.Collapsible__trigger img{
    margin-left:10px;
}

.popup-overlay{
    background-color: #000000cc;
    backdrop-filter: blur(5px);
}
.item-info{
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    margin: 8px 0;
}
.item-info p{
    margin: 0;
}
.item-info .label-info{
    min-width: 90px;
    text-align: left;
    font-weight: 700;
}
.input-info .info-text{
    display: flex;
    max-width: 100%;
    width: 100%;
    text-align: right;
}
.box-footer{
    margin-top: 20px;
    display: flex;
    gap: 10%;
    width: 100%;
    justify-content: center;
    position: relative;
}
.btn-copy-text{
    cursor: pointer;
    max-width: 50%;
    position: relative;
}

.btn-copy-text .ss-copy{
    position: absolute;
    bottom: -16px;
    right: 0;
    color:#15ff54;
    width: 100%;
    font-size: 12px;
    margin: 0;
}

.btn-copy-text button,.btn-send{ 
    position: relative;
    padding: 4px 6px;
    text-align: center;
    background: #eaeaea;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: 2px 2px 3px 1px #cecece;
    cursor:pointer;
    transition: transform ease .15s;
    border: unset;
    transform:scale(0.95);

}
.btn-send a{
    text-decoration: unset;
    color: #fff;
}
.btn-send.type-send{
    min-width: 30%;
    border: 1px solid #FFCD00;
    background: linear-gradient(90deg, #F7B922 0%, #F49A25 100%);
    box-shadow: 0px 2px 2px 0px #F7B922;
}
.btn-wrapper-home{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}
.btn-home{
    min-width: 10%;
    max-width: 100px;
    border: 1px solid #FFCD00;
    background: linear-gradient(90deg, #F7B922 0%, #F49A25 100%);
    box-shadow: 0px 2px 2px 0px #F7B922;
}